import React from "react";
import { graphql } from "gatsby";
import Page from "./Page";
import Section from "../components/UI/Section";
import Container from "../components/UI/Container";
import Markdown from "../components/UI/Markdown";
import CustomImage from "../components/UI/CustomImage";
import ImgMarkupTwoCol from "../components/Blocks/ImgMarkupTwoCol";
import FilesList from "../components/Blocks/FilesList";
import RelatedContent from "../components/Blocks/RelatedContent";
import Banner from "../components/Blocks/Banner";
import blockcollection from "../cms/data/settings/blockcollections.json";
import { Box, Heading, Flex, Text, Button } from "rebass/styled-components";
import PropTypes from "prop-types";

export const ServiceRawTemlate = ({ data, lang }) => {
  const content = data.markdownRemark.frontmatter;

  var blockFields = [];
  var bannerTitleDefault = "test";
  var bannerContentDefault = "test";
  var bannerBtnLabelDefault = "test";
  var bannerBtnHreflDefault = "test";
  blockcollection.blocks.map(function(block, index) {
    if (block.name === "Banner") {
      blockFields = block.fields;
    }
  });
  blockFields.map(function(blockField, index) {
    if (blockField.name === "title") {
      bannerTitleDefault = blockField.default;
    }
    if (blockField.name === "content") {
      bannerContentDefault = blockField.default;
    }
    if (blockField.name === "cta") {
      bannerBtnLabelDefault = blockField.fields[0].default;
      bannerBtnHreflDefault = blockField.fields[1].default;
    }
  });
  let dataBanner = {
    title: bannerTitleDefault,
    content: bannerContentDefault,
    cta: {
      label: bannerBtnLabelDefault,
      href: "/contact"
    }
  };
  if (lang == "en") {
    dataBanner = {
      title: "Need help ?",
      content: "",
      cta: {
        label: "Contact us",
        href: "/en/contact-us"
      }
    };
  }
  return (
    <Section py={(0, 0, 0)}>
      <Container maxContainer="true">
        {content.imageBanner && (
          <Box
            mb={6}
            pt={[5, 5, 10]}
            sx={{
              ".gatsby-image-wrapper": {
                maxHeight: ["auto", "45vw", "400px"]
              }
            }}
          >
            <CustomImage img={content.imageBanner} />
          </Box>
        )}
        {content.maincontent && (
          <Box pb={[5, 5, 10]}>
            <Markdown>{content.maincontent}</Markdown>
          </Box>
        )}
        {content.maincontent2 && (
          <Box pb={[5, 5, 10]}>
            <Markdown>{content.maincontent2}</Markdown>
          </Box>
        )}
        {content.contentTwoCol && content.contentTwoCol.items.length > 0 && (
          <Box
            pb={[5, 5, 10]}
            sx={{
              "& > div > div": {
                px: 0
              }
            }}
          >
            {content.contentTwoCol.items.map((item, i) => (
              <ImgMarkupTwoCol key={i} data={item} />
            ))}
          </Box>
        )}
        {content.filesList && content.filesList.textFiles.length > 0 && (
          <Box pb={5}>
            <Markdown>{content.filesList.textFiles}</Markdown>
          </Box>
        )}
        {content.filesList && (
          <Box
            pb={10}
            sx={{
              "& > div": {
                pt: [0, 0, 0],
                pb: 10,
                "& > div": {
                  px: 0
                }
              }
            }}
          >
            <FilesList data={content.filesList} />
          </Box>
        )}
        {content.relatedContent.related && (
          <Box
            sx={{
              "& > div": {
                pb: 0,
                "& > div": {
                  px: 0
                }
              }
            }}
          >
            <RelatedContent data={content.relatedContent} />
          </Box>
        )}
      </Container>
      <Banner data={dataBanner} />
    </Section>
  );
};

const ServiceTemplate = ({ data, pageContext }) => {
  const infos = {
    title: data.markdownRemark.frontmatter.title,
    description: data.markdownRemark.frontmatter.description
  };
  return (
    <Page
      title={infos.title}
      description={infos.description}
      breadcrumb={pageContext.breadcrumb}
      customCrumbLabel={infos.title}
      lang={pageContext.lang}
    >
      <ServiceRawTemlate data={data} lang={pageContext.lang} />
    </Page>
  );
};

ServiceTemplate.propTypes = {
  data: PropTypes.object
};
ServiceRawTemlate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        body: PropTypes.any
      })
    })
  })
};
export default ServiceTemplate;

export const pageQuery = graphql`
  query ServiceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slug
        title
        description
        maincontent
        maincontent2
        imageBanner {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000, maxHeight: 535) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contentTwoCol {
          title
          items {
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            content
            textPosition
          }
        }
        filesList {
          textFiles
          ctas {
            label
            href {
              publicURL
            }
          }
        }
        relatedContent {
          title
          related {
            title
            href
            image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
